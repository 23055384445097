import * as React from "react";
import Layout from "../components/layout/Layout";
import SectionTitle from "../components/ui/SectionTitle";
import SectionSubtitle from "../components/ui/SectionSubtitle";
import Button from "../components/ui/Button";

const NotFoundPage = () => {
  return (
    <Layout>
      <SectionTitle>404</SectionTitle>
      <SectionSubtitle>Strony nie znaleziono</SectionSubtitle>
      <Button url="/">Strona główna</Button>
    </Layout>
  );
};

export default NotFoundPage;
